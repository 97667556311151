import React from 'react';
import Partner from './partner.png';
const Partners = () => {
  return (
    <div
      class="relative h-fit  py-12 flex flex-col justify-center bgcolorpartner   ggt2  bg-[#3a3838] "
      style={{ 'background-color': '#000000' }}
    >
      <div
        className="bg-[#000000] pb-32  "
        style={{
          'background-color': '#000000',
          backgroundImage: 'url(https://i.ibb.co/V9yy0NS/layer4.png)',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: '1000px 1000px',
        }}
      >
        <div className="p-12 ">
          <h1
            className="text-xl py-6   "
            style={{ color: 'rgba(255, 3, 200, 1)' }}
          >
            Partner
          </h1>
          <h1
            className="text-xl sm:text-5xl text-bold text-white  "
            style={{ fontWeight: '700' }}
          >
            {' '}
            Our Key Partnership
          </h1>
        </div>
        <div className="flex lg:flex-row flex-col gap-6 lg:gap-0  justify-around  sm:my-12 my-auto mt-20 mb-60 banglof w-12/12 mx-auto">
          <img
            className="imgs m-2 p-2 object-contain  h-16  mx-auto "
            src="https://i.ibb.co/1T44DcV/1200px-Amadeus-CRS-Logo-1.png"
            alt="partner"
          />
          <img
            className="imgs m-2 p-2  object-contain  h-20 mx-auto "
            src=" https://i.ibb.co/yXWgGHt/Galileo-Travelport-1.png "
            alt="partner"
          />
          <img
            className="imgs m-2 p-2   object-contain h-16 mx-auto "
            src="https://i.ibb.co/XtrvGqQ/Vector-8.png"
            alt="partner"
          />
          {/* <img
            className="imgs m-2 p-2   object-contain h-16 mx-auto "
            src="https://www.logosvgpng.com/wp-content/uploads/2020/10/duffel-technology-ltd-logo-vector.png"
            alt="partner"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default Partners;
