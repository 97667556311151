import React from 'react';
import Typical from 'react-typical';
const Headertext = () => {
  return (
    <div className=" py-12">
      <h1 className=" text-4xl   lg:text-6xl text-bold text-white font-bold">
        <Typical
          steps={['Building the future  ', 1000]}
          loop={Infinity}
          wrapper="span"
          className="inline-block"
        />
        <br /> technology with <span style={{ color: '#fe1bb3' }}> OTA</span>
      </h1>
    </div>
  );
};

export default Headertext;
