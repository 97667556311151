import emailjs from 'emailjs-com';
import swal from 'sweetalert';

import React from 'react';

const Subscribe = () => {
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_h9hm6mr', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID')
      .then(
        (result) => {
          console.log(result.text);
          swal('Success!', 'Your email has been sent successfully!', 'success');
        },
        (error) => {
          console.log(error.text);
          swal('Error', 'Something went wrong, please try again.', 'error');
        }
      );
  };
  return (
    <div>
      <form onSubmit={sendEmail}>
        <input type="text" name="name" placeholder="Your Name" required />
        <input type="email" name="email" placeholder="Your Email" required />
        <textarea name="message" placeholder="Your Message" required />
        <button type="submit">Send Email</button>
      </form>
    </div>
  );
};

export default Subscribe;
