import React from 'react';
import './Priceing.css';
import bgImage from './layer6.png'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Pricing = () => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll:1,
    initialSlide: 0,
    arrows: false, // Hide navigation arrows
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots:false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
    return (
        <div  style={{ backgroundImage: `url(${bgImage})`,backgroundPosition:'center',backgroundRepeat:'no-repeat',backgroundSize:"cover"  }} className='   h-[750px] sm:h-[960px] bg-[#000102] text-center ' >
            <h2 className='text-xl py-2 text-[#ff03c8] '>Prices</h2>


<h1  className='text-xl sm:text-5xl text-bold text-white  ' style={{fontWeight:"700"}}>See Our Pricing</h1>
<p className='text-xs w-8/12 text-center mx-auto p-1  text-white mt-5'>Everyone has the right to freedom of thought, conscience and religion; this right includes freedom to change his religion or belief, and freedom, either alone or in community with others and in public or private</p>
           <div  className='body2 h-auto mx-auto sm:h-screen ' >
            
           <div   className=" h-[750px] mkt-3dSlider py-6 body2 p-6 rounded-lg shadow-2xl ggt2"   >
            
            {/* <section id="slider-q">
               
              <input class="card-slider qqqqq" type="radio" name="slider-q" id="s1"/>
              <input class="card-slider qqqqq" type="radio" name="slider-q" id="s2"/>
              <input class="card-slider qqqqq" type="radio" name="slider-q" id="s3" checked/> 


              <label for="s1" id="slide1" className="card-slider borderttt rounded-md">
              <div>
                  <div>
                      <h1 className=' xl:text-4xl md:text-2xl text-white sm:text-xl my-8'>Full OTA Poral</h1>
                  </div>
                  <div className='my-2 sm:my-2 '>
                    <p className='text-white text-sm w-10/12 flex mx-auto sm:text-xl '>Everyone has the right to freedom of thought, conscience and religion;m to change </p>
                  </div>
                  <div>
                    <h1 className='text-xl sm:text-5xl text-bold text-white  ' style={{fontWeight:"700"}} > BDT 30,000/-</h1>
                  </div>
                  <div className='mt-7'>
                    <button className='btn sm:btn-lg bg-[#ff03c8] btn-primary btn-xs '>book now</button>
                  </div>
                  <div className='flex mt-6 flex-col element-center ms-2 sm:ms-2'>
                      <div className='flex xl:m-1 xl:mt-12 sm:mt-2 gap-3'>
                          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="9.5" cy="9.5" r="9.5" fill="#D9D9D9"/>
                                    </svg>
                                    <p className='ps-1 text-white text-sm  sm:ps-0'>Everyone has the right to freedom</p>
                      </div>
                      <div className='flex xl:m-1 xl:mt-4 sm:mt-1 gap-3'>
                          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="9.5" cy="9.5" r="9.5" fill="#D9D9D9"/>
                                    </svg>
                                    <p className='ps-1 text-white text-sm  sm:ps-0'>Everyone has the right to freedom</p>
                      </div>
                      <div className='flex xl:m-1 xl:mt-4 sm:mt-1 gap-3'>
                          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="9.5" cy="9.5" r="9.5" fill="#D9D9D9"/>
                                    </svg>
                                    <p className='ps-1 text-white text-sm  sm:ps-0'>Everyone has the right to freedom</p>
                      </div>

                  </div>
                </div>
                
              </label>











              
              <label for="s2" id="slide2" class="card-slider borderttt">
              <div>
                  <div>
                      <h1 className=' xl:text-4xl md:text-2xl text-white sm:text-xl my-8'>Full OTA Poral</h1>
                  </div>
                  <div className='my-2 sm:my-2 '>
                    <p className='text-white text-sm w-10/12 flex mx-auto sm:text-xl '>Everyone has the right to freedom of thought, conscience and religion;m to change </p>
                  </div>
                  <div>
                    <h1 className='text-xl sm:text-5xl text-bold text-white  ' style={{fontWeight:"700"}}> BDT 30,000/-</h1>
                  </div>
                  <div className='mt-7'>
                    <button className='btn sm:btn-lg bg-[#ff03c8] btn-primary btn-xs '>book now</button>
                  </div>
                  <div className='flex mt-6 flex-col element-center ms-2 sm:ms-2'>
                      <div className='flex xl:m-1 xl:mt-12 sm:mt-2 gap-3'>
                          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="9.5" cy="9.5" r="9.5" fill="#D9D9D9"/>
                                    </svg>
                                    <p className='ps-1 text-white text-sm  sm:ps-0'>Everyone has the right to freedom</p>
                      </div>
                      <div className='flex xl:m-1 xl:mt-4 sm:mt-1 gap-3' >
                          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="9.5" cy="9.5" r="9.5" fill="#D9D9D9"/>
                                    </svg>
                                    <p className='ps-1 text-white text-sm  sm:ps-0'>Everyone has the right to freedom</p>
                      </div>
                      <div className='flex xl:m-1 xl:mt-4 sm:mt-1 gap-3'>
                          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="9.5" cy="9.5" r="9.5" fill="#D9D9D9"/>
                                    </svg>
                                    <p className='ps-1 text-white text-sm  sm:ps-0'>Everyone has the right to freedom</p>
                      </div>

                  </div>
                </div>
              </label>

              
              <label for="s3" id="slide3" className=" card-slider borderttt">
              <div>
                  <div>
                      <h1 className=' xl:text-4xl md:text-2xl text-white sm:text-xl my-8'>Full OTA Poral</h1>
                  </div>
                  <div className='my-2 sm:my-2 '>
                    <p className='text-white text-sm w-10/12 flex mx-auto sm:text-xl '>Everyone has the right to freedom of thought, conscience and religion;m to change </p>
                  </div>
                  <div>
                    <h1 className='text-xl sm:text-5xl text-bold text-white  ' style={{fontWeight:"700"}}> BDT 30,000/-</h1>
                  </div>
                  <div className='mt-7'>
                    <button className='btn sm:btn-lg bg-[#ff03c8] btn-primary btn-xs '>book now</button>
                  </div>
                  <div className='flex mt-6 flex-col element-center ms-2 sm:ms-2'>
                      <div className='flex xl:m-1 xl:mt-12 sm:mt-2 gap-3'>
                          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="9.5" cy="9.5" r="9.5" fill="#D9D9D9"/>
                                    </svg>
                                    <p className='ps-1 text-white sm:text-sm  sm:ps-0'>Everyone has the right to freedom</p>
                      </div>
                      <div className='flex xl:m-1 xl:mt-4 sm:mt-1 gap-3'>
                          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="9.5" cy="9.5" r="9.5" fill="#D9D9D9"/>
                                    </svg>
                                    <p className='ps-1 text-white text-sm  sm:ps-0'>Everyone has the right to freedom</p>
                      </div>
                      <div className='flex xl:m-1 xl:mt-4 sm:mt-1 gap-3'>
                          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="9.5" cy="9.5" r="9.5" fill="#D9D9D9"/>
                                    </svg>
                                    <p className='ps-1 text-white text-sm  sm:ps-0'>Everyone has the right to freedom</p>
                      </div>

                  </div>
                </div>
              </label>  
            </section>  */}

<div  className='mx-auto container'>

<Slider {...settings}>

{[1,2,3,5].map((item)=><div>
  <div   className=" borderttt rounded-md "    style={{ background:"rgba(217, 217, 217, 0.22)"}}>
<div className='pb-20'>
                  <div>
                      <h1 className=' xl:text-4xl md:text-2xl text-white sm:text-xl my-8'>Full OTA Poral</h1>
                  </div>
                  <div className='my-2 sm:my-2 '>
                    <p className='text-white text-sm w-10/12 flex mx-auto sm:text-xl '>Everyone has the right to freedom of thought, conscience and religion;m to change </p>
                  </div>
                  <div>
                    <h1 className='text-xl sm:text-5xl text-bold text-white  ' style={{fontWeight:"700"}} > BDT 30,000/-</h1>
                  </div>
                  <div className='mt-7'>
                    <button className='btn sm:btn-lg bg-[#ff03c8] btn-primary btn-xs '>book now</button>
                  </div>
                  <div className='flex mt-6 flex-col element-center ms-2 sm:ms-2'>
                      <div className='flex xl:m-1 xl:mt-12 sm:mt-2 gap-3'>
                          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="9.5" cy="9.5" r="9.5" fill="#D9D9D9"/>
                                    </svg>
                                    <p className='ps-1 text-white text-sm  sm:ps-0'>Everyone has the right to freedom</p>
                      </div>
                      <div className='flex xl:m-1 xl:mt-4 sm:mt-1 gap-3'>
                          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="9.5" cy="9.5" r="9.5" fill="#D9D9D9"/>
                                    </svg>
                                    <p className='ps-1 text-white text-sm  sm:ps-0'>Everyone has the right to freedom</p>
                      </div>
                      <div className='flex xl:m-1 xl:mt-4 sm:mt-1 gap-3'>
                          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="9.5" cy="9.5" r="9.5" fill="#D9D9D9"/>
                                    </svg>
                                    <p className='ps-1 text-white text-sm  sm:ps-0'>Everyone has the right to freedom</p>
                      </div>

                  </div>
                </div>
</div>
</div>)}
</Slider>
</div>
      
            </div>



           </div>
       
        </div>
    );
};

export default Pricing;