import React from 'react';
import Headertext from './Headertext';
import bgImage from './layer6.png';
import logo from '../../../logo.jpg';
import { Grid, Stack } from '@mui/material';
import { IconButton, Tooltip } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';

const Header = () => {
  const handleCall = () => {
    window.location.href = 'tel:+8801797986821';
  };

  const handleEmail = () => {
    window.location.href = 'mailto:info@projectota.com';
  };

  return (
    <div
      className=" bg-black bg-full bg-cover bg-center   bg-auto bg-no-repeat bg-center bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500  to-transparent "
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div className="navbar  container mx-auto ">
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <Grid item>
            <img src={logo} width="120" className="p-1" alt="" />
          </Grid>
          <Grid item>
            {/* <button style={{ color: '#fe1bb3' }}>
              +880 1797 986821 <br />
              <span style={{ color: 'white' }}> info@projectota.com</span>
            </button> */}
            <div style={{ color: 'white' }}>
              <Stack
                direction={'column'}
                sx={{
                  button: {
                    fontSize: 14,
                    p: 0,
                  },
                }}
              >
                <Tooltip title="Call Us">
                  <IconButton
                    onClick={handleCall}
                    sx={{
                      color: '#fe1bb3',
                    }}
                  >
                    <PhoneIcon
                      sx={{
                        fontSize: 16,
                      }}
                    />
                    &nbsp;+880 1797 986821
                  </IconButton>
                </Tooltip>
                <Tooltip title="Email Us">
                  <IconButton onClick={handleEmail} sx={{ color: 'inherit' }}>
                    <MailIcon
                      sx={{
                        fontSize: 16,
                      }}
                    />
                    &nbsp;info@projectota.com
                  </IconButton>
                </Tooltip>
              </Stack>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="py-12 "></div>

      <Headertext></Headertext>
      <div className="py-32"></div>
    </div>
  );
};

export default Header;
