import { Box, Container } from '@mui/material';
import React from 'react';
import Feature from './Feature';

const Service = () => {
  return (
    <div>
      <div class=" font-inter antialiased overflow-hidden">
        <div
          class="  flex flex-col justify-center bg-[#000000]"
          style={{ backgroundImage: `url(" ")` }}
        >
          <Container mb={5}>
            <h1 className="text-2xl  text-[#fe1bb3] pt-4"> Major Features</h1>
            <Feature />
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Service;
