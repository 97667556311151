import React from 'react';

const Outlet = () => {
  return (
    <div className="container  mx-auto">
      <div>
        <div className="relative ttt flex">
          <div className="flex flex-col lg:flex-row justify-between   rounded-lg  shadow-2xl  mx-auto absolute -mt-28  items-center inset-x-1    overflow-visible mttb bgsub p-8 md:p-14">
            <div className="flex flex-col md:flex-[1] ">
              <h1
                className="text-xl sm:text-5xl text-bold text-white  "
                style={{ fontWeight: '700' }}
              >
                Subscribe To Our <br />{' '}
                <span className="text-pink-500">Newsletter</span>
              </h1>
              <p className="text-xs text-center  text-white mt-3">
                {/* lLorem Ipsum is simply dummy text of the printing and
                typesetting industry. */}
              </p>
            </div>

            <div className="relative md:flex-[1]  items-center p-2">
              <div className="  rounded-[20px] bg-gradient-to-r from-teal-300 via-purple-600 to-pink-500 p-[2px]">
                <div className="flex justify-between items-center  w-full bgsub dark:bg-gray-800 rounded-[20px] p-2">
                  <input
                    type="text"
                    placeholder="Enter your Email"
                    className="w-full bg-transparent outline-none border-none px-2 text-white"
                  />
                  <button
                    className="btn border-none"
                    style={{ background: 'rgba(255, 3, 200, 1)' }}
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Outlet;
