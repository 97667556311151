import React from 'react';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Example icon
import { styled } from '@mui/material/styles';

const StyledList = styled(List)({
  color: 'white',
});

const Feature = () => {
  const items = [
    'Services: Bus, flight, hotel, car rentals, holidays, tours, FIT API integrations',
    'Full Level admin Management System',
    'Agent Management System',
    'Manage your agents and subagents',
    'Create B2B, B2B2B and B2B2C white labels, Agents, sub agents',
    'Manage pre markup, post markup, multiple markups and discount structures at various levels',
    'Manage payment and credit accounts of subagents',
    'Manage promotional advertisement and notice board on subagent dashboard',
    'Deposit Management system (Instant top up through payment gateway, credit and deposit)',
    "Manage agent's bookings and payments through advanced reporting system",
    'Integrated the API and GDS for travel agents',
    'Agent can create and manage multiple users',
    'Set credit alert limit and deposits',
    'Responsive agent dashboard',
    'E-wallet system',
    'Distributor model',
  ];

  // Split the items into two halves
  const halfIndex = Math.ceil(items.length / 2);
  const firstHalf = items.slice(0, halfIndex);
  const secondHalf = items.slice(halfIndex);

  return (
    <Grid container spacing={2} mb={5}>
      {/* First Column */}
      <Grid item xs={12} sm={6}>
        <StyledList>
          {firstHalf.map((item, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <CheckCircleIcon style={{ color: 'white' }} />
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </StyledList>
      </Grid>

      {/* Second Column */}
      <Grid item xs={12} sm={6}>
        <StyledList>
          {secondHalf.map((item, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <CheckCircleIcon style={{ color: 'white' }} />
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </StyledList>
      </Grid>
    </Grid>
  );
};

export default Feature;
