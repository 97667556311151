import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import DevicesIcon from '@mui/icons-material/Devices';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
const cards = [
  {
    title: 'User-Friendly Interface',
    desc: 'Focus on business. Don’t struggle with software. Just upload your tour content, and be ready to go. Within a week.',
    icon: <DevicesIcon />,
  },
  {
    title: 'Smart Search and Recommendations',
    desc: 'Tailor your search by date, airline, fare, and more for a personalized booking experience.',
    icon: <TravelExploreIcon />,
  },
  //   {
  //     title: 'Access to an Extensive Low-Cost Flight Inventory',
  //     desc: 'Access a vast selection of low-cost flights from various airlines and routes worldwide.',
  //   },
  {
    title: 'Real-Time Inventory Control & Automated Booking System',
    desc: 'Gain instant access to real-time flight availability and pricing, enabling you to make quick, informed decisions.',
    icon: <DashboardIcon />,
  },
  {
    title: 'Dynamic Pricing and Markup Control & Exclusive Deals',
    desc: 'You can benefit from competitive pricing, exclusive deals, and cost-effective pricing. Sure, it will maximize your profit.',
    icon: <DisplaySettingsIcon />,
  },
];

const About = () => {
  return (
    <div className="py-6 pb-20 bg-[#000000] ">
      <div className=" bg-[#000000] text-center">
        <h1 className="text-2xl  text-[#fe1bb3] pt-4"> Our Services</h1>
        <h1 className="text-2xl  text-[#fe1bb3] py-2">
          Ultimate all-in-one travel software
        </h1>
      </div>
      <Container>
        <Box py={{ xs: 5, md: 8 }}>
          <Grid container spacing={2}>
            {cards.map((data) => (
              <Grid item xs={12} sm={6} md={6}>
                <Box
                  sx={{
                    p: { xs: 2, md: 5 },
                    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                    minHeight: 120,
                  }}
                >
                  <Typography
                    className="text-teal-200"
                    sx={{
                      fontSize: { xs: 14, md: 18 },
                      mb: 1,
                    }}
                  >
                    {data.icon}
                    &nbsp;&nbsp; {data.title}
                  </Typography>
                  <Typography
                    sx={{
                      color: 'white',
                      fontSize: { xs: 12, md: 14 },
                    }}
                  >
                    {data.desc}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default About;
